import React from "react";
import StoryPage from "../../components/story-page";

/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/edith-baxter.jpg";
  const fullName = "Edith Baxter";
  const zoomUrl = "";
  const zoomPhoneNumber = "";
  const zoomMeetingId = "";
  const birthDate = "December 30, 1927";
  const deathDate = "November 3, 2020";
  const memorialDate = "Monday November 9th, 2020";
  const memorialTime = "1pm ET";
  const inviteText = "";
  const obituary = ``;
  const storiesRecordUrl = "";
  const storiesSubmissionsUrl = "";
  const recordingEmbedUrl = "https://www.youtube.com/embed/df4BmNNtI_8";

  return (
    <>
      <StoryPage
        location={location}
        imgUrl={imgUrl}
        fullName={fullName}
        zoomUrl={zoomUrl}
        zoomPhoneNumber={zoomPhoneNumber}
        zoomMeetingId={zoomMeetingId}
        optInviteChild=""
        customColumnSpacing="1fr 2fr"
        birthDate={birthDate}
        deathDate={deathDate}
        memorialDate={memorialDate}
        memorialTime={memorialTime}
        inviteText={inviteText}
        obituary={obituary}
        recordingEmbedUrl={recordingEmbedUrl}
        storiesRecordUrl={storiesRecordUrl}
        storiesSubmissionsUrl={storiesSubmissionsUrl}
      ></StoryPage>
    </>
  );
};

export default ThisPage;
